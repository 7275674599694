<template>
  <div class="card card-body shadow-sm">
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :setting-columns="columns"
      :filters.sync="filters"
      :endpoint-list="ENDPOINT.FACILITY_PLAN_LIST($route.params.facility_id)"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >


      <template v-slot:table-menu-right>
        <button type="button"  class="btn btn-default m-r-10" @click="handlerCancelButton">
          {{ $t('common.back') }}
        </button>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>

      </template>


      <template v-slot:body-cell-apartment_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
             @click="handlerApartmentNameClick(props.row.apartment_id)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.apartment_name}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-facility_id="props">
        <td class="app-align-middle">
          <p :data-original-title="props.row.facility_name" style="color: #007bff;"
             @click="handlerFacilityNameClick(props.row.facility_id)" class="apartment_name app-table-p app-cell-tooltip mb-0">
            {{props.row.facility_name}}
          </p>
        </td>
      </template>


      <template v-slot:body-cell-status_enabled="props">
        <td class="app-align-middle">
          <p :data-original-title="getStatusEnable(props.row.status_enabled)" class="app-table-p app-cell-tooltip mb-0">
            {{getStatusEnable(props.row.status_enabled)}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-price="props">
        <td class="app-align-middle">
          <p  class="app-table-p app-cell-tooltip mb-0 text-right">
            {{convertCurrent(props.row.price_list.replace('¥', ''))}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-tax="props">
        <td class="app-align-middle">
          <p  class="app-table-p app-cell-tooltip mb-0 text-right">
            {{convertCurrent(props.row.tax_list.replace('¥', ''))}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-plan_unit_minutes="props">
        <td class="app-align-middle">
          <p  class="app-table-p app-cell-tooltip mb-0 text-center">
            {{props.row.unit_list}}
          </p>
        </td>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>

        </td>
      </template>
    </app-basic-table>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  import {PLAN_CONSTANT} from "../../../constants/plan";
  export default {
    name: "PlanListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        filters:{},
        paramGetList: {},
        columns: [
          {name: "id", label: this.$t("plans.id"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("plans.mansion"), sortable: true, textAlign: 'text-center'},
          {name: "facility_id", label: this.$t("plans.facilities"), sortable: true},
          {name: "name", label: this.$t("plans.name"), sortable: true},
          {name: "price", label: this.$t("plans.price_without_tax"),sortable: true ,textAlign: 'text-right'},
          {name: "tax", label: this.$t("plans.tax") ,textAlign: 'text-right'},
          {name: "is_reduced_tax_list_page", label: this.$t("plans.is_reduced_tax") ,textAlign: 'text-center'},
          {name: "plan_unit_minutes", label: "利用時間", sortable: true, textAlign: 'text-center'},
          {name: "status_enabled", label: this.$t("information.status"), sortable: true, textAlign: 'text-center'},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    methods: {
     async handlerCancelButton() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.FACILITY_LIST,
          query: {'filters.status_disp_crowded.equal': 'all','perPage' : '99999'}
        });
      },
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_PLAN_CREATE, params: {facility_id: this.$route.params.facility_id}})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.FACILITY_PLAN_EDIT, params: {facility_id: entry.facility_id, id : entry.id}})
      },

      handlerApartmentNameClick(apartmentID) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: apartmentID}});
        window.open(routeApartment.href, '_blank');
      },
      handlerFacilityNameClick(facilityID) {
        let routeFacility = this.$router.resolve({name: this.ROUTES.ADMIN.FACILITY_EDIT, params: {id: facilityID}});
        window.open(routeFacility.href, '_blank');
      },
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.infor_type.equal' : 'all',
            'filters.status_publishing.equal' : 'all',
            'filters.from_date.between' : moment().format('YYYY-MM-DD'),
            'filters.to_date.between' : moment().format('YYYY-MM-DD'),
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      getStatusEnable(typeID) {
        let type = PLAN_CONSTANT.status.find(({id}) => id === typeID);
        if (type == undefined) {
          return '';
        }
        return type.name;
      },
      convertCurrent(value) {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(Number(value));
      }
    },
    mounted: function () {
    },
  }
</script>

<style scoped>

</style>
